<template>
  <div class="point-info__wrapper">
    <div class="point-logo__wrap">
      <img :src="accountLogo" alt="">
    </div>
    <div class="point-info__item">
      <div class="point-info__label">{{ $t('point_manager.current_point') }}</div>
      <div class="point-info__value">
        {{ scoreInfo?.current_score }}
        <img src="@/assets/img/integral.svg">
      </div>
    </div>
    <div class="point-info__item">
      <div class="point-info__label">
        {{ $t('point_manager.get_point') }}
        <span class="action-btn" @click="handleShow('get')">
          <svg-icon name="zhushi"></svg-icon>
        </span>
      </div>
      <div class="point-info__value">
        {{ scoreInfo?.total_score }}
        <img src="@/assets/img/integral.svg">
      </div>
    </div>
    <div class="point-info__item">
      <div class="point-info__label">
        {{ $t('point_manager.used_point') }}
        <span class="action-btn" @click="handleShow('consumption')">
          <svg-icon name="zhushi"></svg-icon>
        </span>
      </div>
      <div class="point-info__value">
        {{ scoreInfo?.used_score }}
        <img src="@/assets/img/integral.svg">
      </div>
    </div>
    <div class="point-info__item action-wrap">
      <div class="point-info__btn" @click="handleRecharge">{{ $t('point_manager.recharge') }}</div>
    </div>
    <info-dialog @close="handleClose" :selectTab="currentTab" :show="showDialog" />
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import InfoDialog from './info-dialog.vue'
import { useState } from '@/hooks/useVuex'
import { useStore } from 'vuex'

const gProperties = getCurrentInstance().appContext.config.globalProperties
const store = useStore()
const showDialog = ref(false)
const currentTab = ref('get')
const accountLogo = computed(() => {
  if (store.getters.userInfo.user_pic && store.getters.userInfo.user_pic.type && store.getters.userInfo.user_pic.base64) {
    return `data:image/${store.getters.userInfo.user_pic.type};base64,${store.getters.userInfo.user_pic.base64}`
  }
  return gProperties.$isDark() ? require('@/assets/img/avatar.png') : require('@/assets/img/avatar-light.png')
})
const { scoreInfo } = useState('user', ['scoreInfo'])

const handleShow = (tab) => {
  showDialog.value = true
  currentTab.value = tab
}

const handleClose = (tab) => {
  showDialog.value = false
  currentTab.value = tab
}

const handleRecharge = () => {
  store.commit('SHOW_RECHANGE_DIALOG')
}

</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.point-info__wrapper {
  height: 180px;
  background: var(--detect-config-bg);
  border-radius: 2px;
  box-sizing: border-box;
  padding: 24px;
  :deep(.el-dialog__header) {
    padding: 0;
  }
  :deep(.el-dialog__header) {
    display: none;
  }
  :deep(.el-dialog__body) {
    height: 360px;
    padding: 0;
    box-sizing: border-box;
  }
  .point-logo__wrap {
    padding: 3px 56px 9px 20px;
    float: left;
    &>img {
      width: 120px;
      border-radius: 60px;
    }
  }
  .point-info__item {
    float: left;
    margin-top: 39px;
    height: 54px;
    text-align: center;
    border-left: 1px solid var(--color-border-2);
    width: calc(25% - 51px);
    .point-info__label {
      color: var(--color-text-3);
      .action-btn {
        color: #00ab7a;
        cursor: pointer;
      }
    }
    .point-info__value {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 17px;
      font-size: 20px;
      color: var(--color-text-2);
      &>img {
        margin-left: 5px;
        width: 14px;
      }
    }
    &.action-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      .point-info__btn {
        cursor: pointer;
        width: 88px;
        line-height: 32px;
        background: linear-gradient(76deg, #f3d8aa 0%, #eabb6d 100%);
        border-radius: 16px;
        font-size: 14px;
        color: #5e3b17;
      }
    }
  }
}
</style>
