<template>
  <el-dialog width="540px" custom-class="point-dialog" v-model="visible" @close="closeDialog">
    <!-- <template #header="{ titleId, titleClass }">
    </template> -->
    <div class="dialog-wrap">
      <div class="header">
        <div class="point-dialog-header">
          <div class="header-item" :class="{ isActive: activeTab === 'get' }" @click="changeTab('get')">
            {{ $t('point_manager.get_point_info') }}
          </div>
          <div class="header-item" :class="{ isActive: activeTab === 'consumption' }" @click="changeTab('consumption')">
            {{ $t('point_manager.used_point_info') }}
          </div>
          <div class="close-wrap" @click="closeDialog">
            <el-icon><Close /></el-icon>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'get'" class="dialog-content">
        <div>{{ $t('point_manager.point_dialog.get_first_line') }}</div>
        <div style="color: var(--color-text-2);margin-top: 22px;font-weight: 600;">{{ $t('point_manager.get_type') }}</div>
        <div style="color: var(--color-text-2);margin-top:3px;">{{ $t('point_manager.point_dialog.get_second_line') }}</div>
        <div style="color: var(--color-text-2);margin-top: 22px;font-weight: 600;">{{ $t('point_manager.rechange_count') }}</div>
        <div style="color: var(--color-text-2);margin-top:3px;">{{ $t('point_manager.point_dialog.get_third_line') }}</div>
      </div>
      <div v-else class="dialog-content">
        <div>{{ $t('point_manager.point_dialog.used_first_line') }}</div>
        <div style="color: var(--color-text-2);margin-top: 22px;font-weight: 600;">{{ $t('point_manager.used_type') }}</div>
        <div style="color: var(--color-text-2);margin-top:3px;">{{ $t('point_manager.point_dialog.used_second_line') }}</div>
        <div class="list-content">
          <div class="list-item">
            <div class="svg-wrap">
              <svg-icon name="huiyuan"></svg-icon>
            </div>
            <div class="item-content__wrap">{{ $t('point_manager.point_dialog.used_third_line') }}</div>
          </div>
          <div class="list-item">
            <div class="svg-wrap">
              <svg-icon name="huiyuan"></svg-icon>
            </div>
            <div class="item-content__wrap">{{ $t('point_manager.point_dialog.used_fourth_line') }}）</div>
          </div>
          <div class="list-item">
            <div class="svg-wrap">
              <svg-icon name="huiyuan"></svg-icon>
            </div>
            <div class="item-content__wrap">{{ $t('point_manager.point_dialog.used_fifth_line') }}</div>
          </div>
          <!-- <div class="list-item">
            <div class="svg-wrap">
              <svg-icon name="huiyuan"></svg-icon>
            </div>
            <div class="item-content__wrap">公开任务展示时间范围：专业版 1 天（30 积分）；专业增强版 1 天（50 积分）</div>
          </div> -->
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from 'vue'
import { Close } from '@element-plus/icons-vue'

const visible = ref(false)
const activeTab = ref('get')

const props = defineProps({
  show: {
    required: true,
    default: false
  },
  selectTab: {
    required: true,
    default: 'get'
  }
})

const emit = defineEmits(['close'])

watch(() => props.show, (val) => {
  visible.value = val
})

watch(() => props.selectTab, (val) => {
  activeTab.value = val
})

const changeTab = (tab) => {
  activeTab.value = tab
}

const closeDialog = () => {
  // visible.value = false
  emit('close', activeTab)
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
[lang="en"] {
  .point-dialog-header .header-item {
    width: 240px;
  }
  .dialog-content {
    height: 300px;
  }
}
.point-dialog-header {
  overflow: hidden;
  .header-item {
    font-size: 14px;
    color: var(--color-text-2);
    width: 160px;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    text-align: center;
    float: left;
    cursor: pointer;
    &.isActive {
      color: var(--color-text-1);
      font-weight: 600;
      border-bottom: 2px solid #05966f;
    }
  }
  .close-wrap {
    float: right;
    margin-right: 22px;
    margin-top: 15px;
    font-size: 16px;
    cursor: pointer;
    :hover {
      color: var(--color-priamry);
    }
  }
}
.dialog-content {
  line-height: 20px;
  height: 270px;
  padding: 24px;
  box-sizing: border-box;
  .list-content {
    .list-item {
      margin-top: 6px;
      overflow: hidden;
      .svg-wrap {
        float: left;
        color: #d9bf18;
      }
      .item-content__wrap {
        width: calc(100% - 30px);
        margin-left: 5px;
        float: left;
      }
    }
  }
}
</style>
