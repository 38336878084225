<template>
  <div class="point-wrapper">
    <div class="point-title">
      {{ $t('point_manager.title') }}
    </div>
    <div class="ponit-info__wrapper">
      <point-info />
    </div>
    <div class="point-content__wrapper">
      <div class="point-tabs_wrapper">
        <div class="point-tab__use" @click="changeTab('use')" :class="{ active: currentTab === 'use' }">{{ $t('point_manager.point_benefits') }}</div>
        <div class="point-tab__record" @click="changeTab('record')" :class="{ active: currentTab === 'record' }">{{ $t('point_manager.point_record') }}</div>
      </div>
      <ponit-use v-if="currentTab === 'use'" />
      <point-record v-if="currentTab === 'record'" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ponitUse from './components/point-use.vue'
import pointRecord from './components/point-record.vue'
import pointInfo from './components/point-info.vue'

const currentTab = ref('use')

const changeTab = (tab) => {
  if (currentTab.value !== tab) {
    currentTab.value = tab
  }
}

</script>
<style lang="scss" scoped>
@import '@/styles/common.scss';
[lang="en"] .point-wrapper .point-content__wrapper .point-tabs_wrapper {
  &>div {
    width: 130px;
  }
}
.point-wrapper {
  padding: 0 24px;
  padding-bottom: 24px;
  min-height: calc(100% - 85px);
  .point-title {
    line-height: 50px;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text-1);
  }
  .point-content__wrapper {
    .point-tabs_wrapper {
      margin-top: 15px;
      overflow: hidden;
      &>div {
        float: left;
        width: 104px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        &.active {
          background: var(--detect-config-bg);
          border-radius: 2px 2px 0px 0px;
        }
      }
    }
  }
}
</style>
