<template>
  <div class="exhibition">
    <!-- <div class="exhibition-noData" v-if="cardData.length === 0">暂无积分权益数据</div> -->
    <sandNoData v-if="cardData.length === 0" :text="$t('point_manager.no_data')" />
    <div v-else class="exhibition-card" :class="`exhibition-card${item.type}`" v-for="(item, index) in cardData" :key="index">
      <div class="exhibition-card__title">
        {{item.title}}
        <div class="exhibition-card__content" v-if="item.content">「{{item.content}}」</div>
      </div>
      <div class="exhibition-card__total">
        {{item.total}}{{item.after2}}
        <span class="exhibition-card__after">{{item.after}}</span>
      </div>
      <div class="exhibition-card__bottomText">
        {{item.bottomText}} {{item.bottomValue}}{{item.after2}}</div>
    </div>
  </div>
</template>

<script setup>
// ➜  ~ fontmin -t "0123456789现有积分次天充值小时个" txw7.ttf
import SandNoData from '@/components/report/sandNoData'
import { useStore } from 'vuex'
import { ref, computed, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue'
import dayjs from 'dayjs'
import { useState } from '@/hooks/useVuex'

const { appContext: { config: { globalProperties } } } = getCurrentInstance()

const { scoreInfo } = useState('user', ['scoreInfo'])

const timeDown = ref(1)
const store = useStore()
const interval = ref(null)
const cardInfoMap = {
  detection_pro_last: {
    type: 2,
    title: globalProperties.$t('UserGroup.professional'),
    content: globalProperties.$t('point_manager.point_desc.task_conf_benefits'),
    total: '',
    after: globalProperties.$t('point_manager.point_desc.available_count'),
    after2: globalProperties.$t('account_manager.subscription.bout'),
    bottomText: globalProperties.$t('point_manager.point_desc.used'),
    bottomValue: 'detection_pro_total'
  },
  detection_enter_last: {
    type: 1,
    title: globalProperties.$t('UserGroup.enterprise'),
    content: globalProperties.$t('point_manager.point_desc.task_conf_benefits'),
    total: '',
    after: globalProperties.$t('point_manager.point_desc.available_count'),
    after2: globalProperties.$t('account_manager.subscription.bout'),
    bottomText: globalProperties.$t('point_manager.point_desc.used'),
    bottomValue: 'detection_enter_total'
  },
  report_pro_expire: {
    type: 2,
    title: globalProperties.$t('UserGroup.professional'),
    content: globalProperties.$t('point_manager.point_desc.show_report_content'),
    total: '',
    after: globalProperties.$t('point_manager.point_desc.available_time'),
    bottomText: globalProperties.$t('point_manager.point_desc.expire_date'),
    bottomValue: ''
  },
  report_enter_expire: {
    type: 1,
    title: globalProperties.$t('UserGroup.enterprise'),
    content: globalProperties.$t('point_manager.point_desc.show_report_content'),
    total: '',
    after: globalProperties.$t('point_manager.point_desc.available_time'),
    bottomText: globalProperties.$t('point_manager.point_desc.expire_date'),
    bottomValue: ''
  },
  report_range_pro_expire: {
    type: 2,
    title: globalProperties.$t('UserGroup.professional'),
    content: globalProperties.$t('point_manager.point_desc.show_sample_tiem_range'),
    total: '',
    after: globalProperties.$t('point_manager.point_desc.available_time'),
    bottomText: globalProperties.$t('point_manager.point_desc.expire_date'),
    bottomValue: ''
  },
  report_range_enter_expire: {
    type: 1,
    title: globalProperties.$t('UserGroup.enterprise'),
    content: globalProperties.$t('point_manager.point_desc.show_sample_tiem_range'),
    total: '',
    after: globalProperties.$t('point_manager.point_desc.available_time'),
    bottomText: globalProperties.$t('point_manager.point_desc.expire_date'),
    bottomValue: ''
  },
  download_last: {
    type: 5,
    title: globalProperties.$t('point_manager.point_desc.download_file'),
    total: '',
    after: globalProperties.$t('point_manager.point_desc.available_count'),
    after2: globalProperties.$t('account_manager.subscription.indivual'),
    bottomText: globalProperties.$t('point_manager.point_desc.used'),
    bottomValue: 'download_total'
  }
  // sample_down_last: {
  //   type: 5,
  //   title: '下载样本',
  //   total: '',
  //   after: '（可用数量）',
  //   after2: '个',
  //   bottomText: globalProperties.$t('point_manager.point_desc.used'),
  //   bottomValue: 'sample_down_total'
  // },
  // release_down_last: {
  //   type: 5,
  //   title: '下载释放文件',
  //   total: '',
  //   after: '（可用数量）',
  //   after2: '个',
  //   bottomText: globalProperties.$t('point_manager.point_desc.used'),
  //   bottomValue: 'release_down_total'
  // },
  // pcap_down_last: {
  //   type: 5,
  //   title: '下载PCAP',
  //   total: '',
  //   after: '（可用数量）',
  //   after2: '个',
  //   bottomText: globalProperties.$t('point_manager.point_desc.used'),
  //   bottomValue: 'pcap_down_total'
  // },
  // memdumps_down_last: {
  //   type: 5,
  //   title: '下载释放内存',
  //   total: '',
  //   after: '（可用数量）',
  //   after2: '个',
  //   bottomText: globalProperties.$t('point_manager.point_desc.used'),
  //   bottomValue: 'memdumps_down_total'
  // }
}

onMounted(() => {
  interval.value = setInterval(() => {
    timeDown.value++
  }, 1000)
  store.dispatch('user/getScoreInfo')
})

onBeforeUnmount(() => {
  interval.value && clearInterval(interval.value)
})
const cardData = computed(() => {
  if (!scoreInfo.value) return []
  let data = timeDown.value
  data = []
  Object.keys(scoreInfo.value).forEach(item => {
    // 时间类型的可能性
    if (scoreInfo.value[item]?.$date > new Date().getTime() && cardInfoMap[item]) {
      data.push({
        ...cardInfoMap[item],
        total: getDate(scoreInfo.value[item]?.$date),
        bottomValue: dayjs(scoreInfo.value[item]?.$date).format('YYYY-MM-DD HH:mm:ss')
      })
    } else if (scoreInfo.value[item] && !scoreInfo.value[item].$date && cardInfoMap[item]) {
      data.push({
        ...cardInfoMap[item],
        total: scoreInfo.value[item],
        bottomValue: scoreInfo.value[cardInfoMap[item].bottomValue] - scoreInfo.value[item]
      })
    }
  })
  return data
})
const getDate = (time) => {
  if (!time) return globalProperties.$t('tip.unknow')
  time = time - new Date().getTime()
  if (time < 60000) return `0${globalProperties.$t('account_manager.subscription.hour')}1${globalProperties.$t('account_manager.subscription.minute')}`
  const min = time / 60000
  return `${parseInt(min / 60)}${globalProperties.$t('account_manager.subscription.hour')}${parseInt(min % 60)}${globalProperties.$t('account_manager.subscription.minute')}`
}
</script>

<style lang="scss" scoped>
[data-theme=light]{
  .exhibition {
    &-card {
      background-image: url('~@/assets/img/user-center/item-bg-light.png') !important;
    }
  }
}

.exhibition {
  overflow: hidden;
  // display: flex;
  // flex-flow: row wrap;
  // justify-content: space-between;

  background: var(--detect-config-bg);
  box-sizing: border-box;
  height: calc(100vh - 430px);
  overflow-y: auto;
  padding: 9px 24px 24px;
  color: var(--color-text-2);
  &-noData {
    padding-top: 15px;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
  &-card {
    height: 137px;
    // width: 285px;
    float: left;
    background-image: url('~@/assets/img/user-center/item-bg.svg');
    background-size: auto 100%;
    background-position: top 0 right 0;
    background-repeat: no-repeat;
    // background-color: #40425166;
    background-color: var(--user-subscribe-card);
    width: calc((100% - 45px)/4);
    box-sizing: border-box;
    border-radius: 4px;
    // margin: 6px;
    // background-size: 100% 100%;
    position: relative;
    padding-left: 20px;
    margin-left: 15px;
    margin-top: 15px;
    &:nth-child(4n + 1) {
      margin-left: 0;
    }
    // padding-bottom: 10px;
    &__title {
      margin-top: 18px;
      font-size: 16px;
      font-weight: 400;
    }
    &__content {
      font-size: 16px;
      display: inline-block;
      font-weight: 300;
    }
    &__total {
      margin-top: 22px;
      font-family: "TencentSans W7";
      font-size: 20px;
    }
    &__after {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      margin-left: -5px;
    }
    &__bottomText {
      width: 100%;
      height:0pt 30px;
      line-height: 30px;
      padding-left: 20px;
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      font-size: 12px;
    }
    &1 {
      // background-image: url('./../../assets/img/integralCard1.png');
    }
    &2 {
      // background-image: url('./../../assets/img/integralCard2.png');
    }
    &3 {
      // background-image: url('./../../assets/img/integralCard3.png');
    }
    &4 {
      // background-image: url('./../../assets/img/integralCard4.png');
      & .exhibition-card__title,
      & .exhibition-card__content,
      & .exhibition-card__total,
      & .exhibition-card__after,
      & .exhibition-card__bottomText {
        color: #F9DFB0;
      }
    }
    &5 {
      // background-image: url('./../../assets/images/integralCard4.png');
      & .exhibition-card__content {
        margin-top: 30px;
        font-size: 16px;
      }
      & .exhibition-card__total {
        margin-top: 25px;
      }
    }
  }
}
</style>
