<template>
  <div class="point-record__wrapper">
    <div class="list-wrap">
      <el-table
        :data="tableData"
        style="width: 100%"
        @filter-change="filterHandler"
      >
        <el-table-column prop="date" :label="$t('point_manager.time')" width="300" />
        <el-table-column
          prop="use_score"
          :label="$t('point_manager.point_change')"
          width="240"
          :filter-multiple="false"
          filter-placement="bottom"
          :filters="[
            { text: $t('point_manager.get'), value: 'increase' },
            { text: $t('point_manager.used'), value: 'consume' }
          ]"
        />
        <el-table-column prop="content" :label="$t('point_manager.content_change')" />
        <template #empty>
          <div class="empty">
            <sandNoData :text="$t('tip.noData')" />
          </div>
        </template>
      </el-table>
    </div>
    <div class="pagination-wrap">
      <Pagination
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :totalSize="totalSize"
        :current_page="currentPage"
      />
    </div>
  </div>
</template>

<script setup>
import SandNoData from '@/components/report/sandNoData'
import { ref, onMounted } from 'vue'
import Pagination from '@/components/common/Pagination'
import { getScoreList } from '@/services/score'
import dayjs from 'dayjs'

const tableData = ref([
])
const currentPage = ref(1)
const totalSize = ref(0)
const pageSize = ref(50)

onMounted(() => {
  getData()
})

const handleSizeChange = (size) => {
  pageSize.value = size
  handleCurrentChange(1)
}
const handleCurrentChange = (page) => {
  currentPage.value = page
  getData()
}

const filterHandler = (type, ...arg) => {
  const selectType = Object.values(type)[0] && Object.values(type)[0].length ? Object.values(type)[0][0] : 'all'
  console.log(selectType)
  let use_type = ''
  if (selectType === 'all') {
    use_type = undefined
  } else if (selectType === 'consume') {
    use_type = 1
  } else {
    use_type = 2
  }
  getData({
    use_type
  })
}

const getData = (parms) => {
  getScoreList({
    ...parms,
    page: currentPage.value,
    size: pageSize.value
  }).then(res => {
    tableData.value = res.data.map(item => {
      return {
        ...item,
        date: dayjs(item.created_at.$date).format('YYYY-MM-DD HH:mm:ss'),
        use_score: item.number ? (item.use_type === 0 || item.use_type === 2 ? '+' + item.number : '-' + item.number) : item.number
      }
    })
    totalSize.value = res.total
  })
}

</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.point-record__wrapper {
  background: var(--detect-config-bg);
  // padding: 24px;
  box-sizing: border-box;
  min-height: calc(100vh - 430px);
  .list-wrap {
    padding: 24px;
    :deep(th) {
      background-color: var(--color-bg-3);
    }
    :deep(.el-table__column-filter-trigger) {
      margin-left: 7px;
    }
    :deep(.highlight.cell) {
      color: var(--color-text-1) !important;
      .el-table__column-filter-trigger > .el-icon {
        color: #00ab7a;
      }
    }
  }
  .pagination-wrap {
    padding-right: 3px;
    padding-bottom: 24px;
  }
  .empty {
    height: calc(100vh - 542px);
  }
}
</style>
<style lang="scss">
.el-table-filter {
  border: none !important;
  background-color: var(--detect-config-bg);
  .el-table-filter__list {
    padding: 6px 0;
    width: 116px;
    .el-table-filter__list-item {
      line-height: 32px;
      // width: 76px;
      color: var(--color-text-1);
      text-align: center;
      &.is-active {
        background-color: var(--color-bg-3);
      }
      &:hover {
        background-color: var(--color-bg-3);
      }
    }
  }
}
</style>
